import React from 'react'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import LocalizedLink from '../LocalizedLink'

const Footer = () => (
  <div className="container text-center">
    <footer className="footer">
      <div className="cookie">
        {/* <LocalizedLink to="/cookie">
        Privacy & Cookie Policy
        </LocalizedLink> */}

        <a href="https://kns-web.s3.eu-central-1.amazonaws.com/files/KNS-privacy.pdf" target="_blank"> Privacy & Cookie Policy </a>


      </div>
      <br />
      <p>Copyright © KNS. All rights reserved.</p>
    </footer>
  </div>
)

export default Footer
