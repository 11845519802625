import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../../components/Layout'
import Footer from '../../components/footer'
import facebooklogo from '../../assets/images/icon/facebook.svg'
import linkedinlogo from '../../assets/images/icon/linkedin.svg'
import knsiconwhite from '../../assets/images/icon/kns-icon-white.svg'
import locimage from '../../assets/images/loc.jpeg'
import $ from 'jquery'
import LocalizedLink from '../../LocalizedLink'

class ProductLedDisplay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProject: null,
      images: [],
    }
  }
  setSelectedProject(name, array) {
    this.setState({ selectedProject: name, images: array })
  }

  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="dt-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <section
            className="solution-detail digital-directory"
            style={{ backgroundImage: 'url(' + locimage + ')' }}
          >
            <div className="dark-filter" />
            <section className="page">
              <div className="container">
                <div className="col-12">
                  <figure className="kns-grey-icon">
                    <img src={knsiconwhite} />
                  </figure>
                  <div className="page-prp">
                    <h1>Venuex</h1>
                    {this.props.pageContext.locale === 'tr' && (
                      <>
                        <p>
                          Meet Venuex now and help your business break down the
                          walls. With the indoor navigation services we offer;
                          let the world notice you and let your visitors have a
                          unique experience across all mobile devices.
                        </p>
                      </>
                    )}
                    {this.props.pageContext.locale === 'en' && (
                      <>
                        <p>
                          Meet Venuex now and help your business break down the
                          walls. With the indoor navigation services we offer;
                          let the world notice you and let your visitors have a
                          unique experience across all mobile devices.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/* <div className="tags"><span className="software">Software</span><span className="hardware">Hardware</span></div>*/}
          </section>

          <div className="container">
            <article className="dt-content venuex-page">
              <p>Venuex;</p>
              <div className="row content-prp">
                <div className="col-12 text-left">
                  {this.props.pageContext.locale === 'tr' && (
                    <>
                      <ul>
                        <li>
                          Offers indoor map and navigation services for your
                          business on platforms such as Apple Maps, Google Maps
                          and so forth.
                        </li>
                        <li>
                          Secures your brand by transferring all your business
                          data on an up-to-date,safe platform.
                        </li>
                        <li>
                          Increases your online visibility and brand awareness.
                        </li>
                        <li>
                          Introduces a whole new perspective to visitor
                          experience by reaching out to your visitors in the
                          right place, at the right time, and with right
                          content.
                        </li>
                        <li>
                          Increases access to stores, restaurants, elevators,
                          escalators, exit gates, shuttle services, and all the
                          other significant spots in your venue.
                        </li>
                      </ul>
                    </>
                  )}
                  {this.props.pageContext.locale === 'en' && (
                    <>
                      <ul>
                        <li>
                          Offers indoor map and navigation services for your
                          business on platforms such as Apple Maps, Google Maps
                          and so forth.
                        </li>
                        <li>
                          Secures your brand by transferring all your business
                          data on an up-to-date,safe platform.
                        </li>
                        <li>
                          Increases your online visibility and brand awareness.
                        </li>
                        <li>
                          Introduces a whole new perspective to visitor
                          experience by reaching out to your visitors in the
                          right place, at the right time, and with right
                          content.
                        </li>
                        <li>
                          Increases access to stores, restaurants, elevators,
                          escalators, exit gates, shuttle services, and all the
                          other significant spots in your venue.
                        </li>
                      </ul>
                    </>
                  )}

                  <span>Visit website: </span>
                  <a href="http://www.venuex.io" target="_blank">
                    venuex.io
                  </a>
                </div>
              </div>
            </article>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <Footer />
        </body>
      </Layout>
    )
  }
}
ProductLedDisplay.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProductLedDisplay
